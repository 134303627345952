import { ENTITIES_PERMISIONS } from 'components/common/PermissionsGate/constants';
import { URLS } from './constants';

export default (findEntity, isReturnInboxEnabled) => [
  {
    title: 'Overview',
    icon: 'home.svg',
    options: [
      {
        title: 'Power Bi',
        url: URLS.home,
      },
      {
        title: 'Daily Bookings',
        url: URLS.dailyBookings,
        entity: ENTITIES_PERMISIONS.ReadBookingsGraph,
      },
    ],
    hide: !(findEntity([ENTITIES_PERMISIONS.ReadBookingsGraph]) === 0),
  },
  {
    title: 'Sales',
    icon: 'sales.svg',
    options: [
      {
        title: 'Bookings',
        url: URLS.bookings,
        entity: ENTITIES_PERMISIONS.ReadBookings,
      },
      {
        title: 'Availability Table',
        url: URLS.availability,
      },
      {
        title: 'Reservations',
        url: URLS.reservations,
      },
      {
        title: 'Campaigns',
        url: URLS.campaigns,
      },
    ],
  },
  {
    title: 'Fleet',
    icon: 'fleet.svg',
    options: [
      {
        title: 'Vehicles',
        url: URLS.fleetVehicles,
        entity: ENTITIES_PERMISIONS.ReadVehicles,
      },
      {
        title: 'Logistics',
        url: '#0',
      },
    ],
    hide: !findEntity([
      ENTITIES_PERMISIONS.ReadVehicles,
      ENTITIES_PERMISIONS.ReadBuyouts,
    ]),
  },
  {
    title: 'Sell Vehicles',
    icon: 'sellVehicles.svg',
    options: [
      {
        title: 'Vehicles',
        url: URLS.sellVehicles,
        entity: ENTITIES_PERMISIONS.ReadVehicles,
      },
      {
        title: 'Leasing Buyouts',
        url: URLS.buyout,
        path: 'buyout',
        entity: ENTITIES_PERMISIONS.ReadBuyouts,
      },
      {
        title: 'Requests',
        url: URLS.sellRequests,
        path: 'sellRequests',
        entity: ENTITIES_PERMISIONS.ReadBuyouts, // Change to ReadSaleRequests
      },
      {
        title: 'Defleet',
        url: URLS.defleet,
        path: 'defleet',
        entity: ENTITIES_PERMISIONS.ReadDefleets,
      },
    ],
    hide: !findEntity([
      ENTITIES_PERMISIONS.ReadVehicles,
      ENTITIES_PERMISIONS.ReadBuyouts,
    ]),
  },
  {
    title: 'Customer Success',
    icon: 'leasing.svg',
    options: [
      {
        title: 'Inbox',
        url: URLS.returns,
        hide: !isReturnInboxEnabled || isReturnInboxEnabled === 'false',
      },
      {
        title: 'Dashboard',
        url: URLS.customerSuccessDashboard,
      },
      {
        title: 'Subscriptions',
        url: URLS.subscriptions,
        entity: ENTITIES_PERMISIONS.ReadSubscriptions,
      },
      {
        title: 'Green Card',
        url: URLS.greenCard,
        entity: ENTITIES_PERMISIONS.ReadGreenCards,
      },
      {
        title: 'Traffic offenses',
        url: URLS.trafficOffenses,
        path: 'trafficOffenses',
        entity: ENTITIES_PERMISIONS.ReadTrafficOffenses, // Change to ReadSaleRequests
      },
    ],
    hide: !findEntity([
      ENTITIES_PERMISIONS.ReadSubscriptions,
      ENTITIES_PERMISIONS.ReadTrafficOffenses,
      ENTITIES_PERMISIONS.ReadGreenCards,
    ]),
  },
  {
    title: 'AR&M',
    icon: 'arm.svg',
    options: [
      {
        title: 'Dashboard',
        url: URLS.arnmDashboard,
      },
      {
        title: 'Repairs',
        url: URLS.arnmRepairs,
        entity: ENTITIES_PERMISIONS.ReadRepairs,
      },
      {
        title: 'Maintenance',
        url: URLS.arnmMaintenance,
        entity: ENTITIES_PERMISIONS.ReadMaintenance,
      },
      {
        title: 'Accidents/Claims',
        url: URLS.arnmAccidents,
        entity: ENTITIES_PERMISIONS.ReadAccidentsClaims,
      },
    ],
    hide: !findEntity([
      ENTITIES_PERMISIONS.ReadRepairs,
      ENTITIES_PERMISIONS.ReadMaintenance,
      ENTITIES_PERMISIONS.ReadAccidentsClaims,
    ]),
  },
  {
    title: 'Billing',
    icon: 'billing.svg',
    hide: !findEntity([ENTITIES_PERMISIONS.ReadBillingTickets]),
    url: URLS.billing,
  },
  {
    title: 'Services',
    icon: 'services.svg',
    options: [
      {
        title: 'Inbox',
        url: URLS.serviceVehicles,
        entity: ENTITIES_PERMISIONS.ReadServiceVehicles,
      },
      {
        title: 'Insurances',
        url: URLS.insurances,
        entity: ENTITIES_PERMISIONS.ReadInsurances,
      },
      {
        title: 'Roadside Assistance',
        url: URLS.roadsideAssistance,
        entity: ENTITIES_PERMISIONS.ReadRoadsideAssistance,
      },
      {
        title: 'Service Points',
        url: URLS.servicePoints,
        entity: ENTITIES_PERMISIONS.ReadServicePoints,
      },
    ],
    hide: !findEntity([
      ENTITIES_PERMISIONS.ReadServiceVehicles,
      ENTITIES_PERMISIONS.ReadInsurances,
      ENTITIES_PERMISIONS.ReadRoadsideAssistance,
      ENTITIES_PERMISIONS.ReadServicePoints,
    ]),
  },
  {
    title: 'Products',
    icon: 'sales.svg',
    options: [
      {
        title: 'Table',
        url: '/products',
      },
    ],
  },
  {
    title: 'Catalog',
    icon: 'catalog.svg',
    options: [
      {
        title: 'Cars',
        url: URLS.catalogCars,
        entity: ENTITIES_PERMISIONS.ReadVehicleCatalog,
      },
      {
        title: 'Ebikes',
        url: URLS.catalogEbikes,
        entity: ENTITIES_PERMISIONS.ReadVehicleCatalog,
      },
    ],
    hide: !findEntity([
      ENTITIES_PERMISIONS.ReadVehicleCatalog,
      ENTITIES_PERMISIONS.ReadVehicleCatalog,
      ENTITIES_PERMISIONS.ReadVehicleCatalog,
    ]),
  },
  {
    title: 'Marketplace',
    icon: 'marketplace.svg',
    options: [
      {
        title: 'Tires',
        url: '#0',
      },
      {
        title: 'Car Wash',
        url: '#0',
      },
      {
        title: 'Vendors',
        url: URLS.vendors,
        hide: true,
      },
      {
        title: 'Orders',
        url: URLS.orders,
        entity: ENTITIES_PERMISIONS.ReadOrders,
      },
    ],
  },
  {
    title: 'Drverse',
    icon: 'drverse.svg',
    options: [
      {
        title: 'Campaigns',
        url: URLS.driverseCampaigns,
        entity: ENTITIES_PERMISIONS.ReadDriverse,
      },
    ],
    hide: true, // !findEntity([ENTITIES_PERMISIONS.ReadDriverse]),
  },
  {
    title: 'CRM',
    icon: 'crm.svg',
    options: [
      {
        title: 'Users',
        url: URLS.users,
        entity: ENTITIES_PERMISIONS.ReadUsers,
      },
      {
        title: 'Payments',
        url: '#0',
      },
    ],
    hide: !findEntity([ENTITIES_PERMISIONS.ReadUsers]),
  },
  {
    title: 'Drivers',
    icon: 'steeringWheel.svg',
    options: [
      {
        title: 'Delivery',
        url: URLS.handoverCarsDelivery,
        entity: ENTITIES_PERMISIONS.ReadDeliveryReturn,
      },
      {
        title: 'Returns',
        url: URLS.handoverCarsReturns,
        entity: ENTITIES_PERMISIONS.ReadDeliveryReturn,
      },
      {
        title: 'Inspections',
        url: URLS.inspections,
        entity: ENTITIES_PERMISIONS.ReadDeliveryReturn,
      },
    ],
    hide: !findEntity([ENTITIES_PERMISIONS.ReadDeliveryReturn]),
  },
  {
    title: 'Unified View',
    icon: 'mail.svg',
    options: [
      {
        title: 'Communication',
        url: URLS.communication,
        entity: ENTITIES_PERMISIONS.ReadEmails,
      },
    ],
    hide: !findEntity([ENTITIES_PERMISIONS.ReadEmails]),
  },
  {
    title: 'Credit Check',
    icon: 'reports.svg',
    options: [
      {
        title: 'Reports',
        url: URLS.creditCheckReport,
        entity: ENTITIES_PERMISIONS.ReadPipedriveEvents,
      },
    ],
    hide: !findEntity([ENTITIES_PERMISIONS.ReadPipedriveEvents]),
  },
  {
    title: 'Reports',
    icon: 'reports.svg',
    options: [
      {
        title: 'Instapayment Reports',
        url: URLS.instapaymentReports,
        entity: ENTITIES_PERMISIONS.ReadInstapaymentReport,
      },
      {
        title: 'AVIS Report',
        url: URLS.avisReport,
        entity: ENTITIES_PERMISIONS.ReadAvisReport,
      },
      {
        title: 'ARNM Reports',
        url: '/arnm_sla_reports',
        entity: ENTITIES_PERMISIONS.ReadARNMReport,
      },
    ],
    hide: !findEntity([
      ENTITIES_PERMISIONS.ReadInstapaymentReport,
      ENTITIES_PERMISIONS.ReadAvisReport,
      ENTITIES_PERMISIONS.ReadARNMReport,
    ]),
  },
  {
    title: 'Utilities',
    icon: 'utilities.svg',
    options: [
      {
        title: 'Tools',
        url: URLS.utilities,
        entity: !findEntity([
          ENTITIES_PERMISIONS.UseVatFinder,
          ENTITIES_PERMISIONS.UseOCR,
        ]),
      },
      {
        title: 'Logger',
        url: URLS.errors,
        entity: ENTITIES_PERMISIONS.ReadErrors,
      },
    ],
    hide: !findEntity([
      ENTITIES_PERMISIONS.UseVatFinder,
      ENTITIES_PERMISIONS.UseOCR,
      ENTITIES_PERMISIONS.ReadErrors,
    ]),
  },
  {
    title: 'Admin Area',
    icon: 'admin.svg',
    options: [
      {
        title: 'Agents',
        url: URLS.agents,
        entity: ENTITIES_PERMISIONS.ReadAgents,
      },
      {
        title: 'Teams',
        url: URLS.teams,
        entity: ENTITIES_PERMISIONS.ReadTeams,
      },
      {
        title: 'Configuration',
        url: URLS.configurations,
        entity: ENTITIES_PERMISIONS.ReadConfigurations,
      },
      {
        title: 'Permissions',
        url: URLS.permissions,
        entity: ENTITIES_PERMISIONS.ReadPermissions,
      },
      {
        title: 'Partners',
        url: URLS.partners,
        entity: ENTITIES_PERMISIONS.ReadPartners,
      },
      {
        title: 'Email Boxes',
        url: '/mailBoxes',
        entity: ENTITIES_PERMISIONS.ReadMailboxes,
      },
    ],
    hide: !findEntity([
      ENTITIES_PERMISIONS.ReadAgents,
      ENTITIES_PERMISIONS.ReadConfigurations,
      ENTITIES_PERMISIONS.ReadPermissions,
      ENTITIES_PERMISIONS.ReadPartners,
      ENTITIES_PERMISIONS.ReadMailboxes,
    ]),
  },
];
